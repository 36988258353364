import React, { useState } from 'react';
import { Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import {
  IndividualStepWrap,
  StepHeader,
  SubmitButton,
  PreviousNextWrap,
  RightArrow,
} from './styles';

const heardAboutUsOptions = [
  'Referral',
  'Podcast',
  'LinkedIn',
  'Twitter',
  'BiggerPockets',
  'Facebook',
  'Other',
];

export default function AdditionalInformation({
  request,
  setRequest,
  onSubmit,
  submitting,
  prevStep,
}) {
  const onHeardAboutUsChange = (event) => {
    setRequest((draft) => {
      draft.heardAboutUs = event.target.value;
    });
  };
  const onAdditionalCommentsChange = (event) => {
    setRequest((draft) => {
      draft.additionalComments = event.target.value;
    });
  };

  return (
    <IndividualStepWrap>
      <StepHeader>Additional Information</StepHeader>
      <Grid container spacing={{ xs: 2, sm: 3 }}>
        <Grid item sm={12}>
          <div className='dropzone-title mt4 mb1'>
            <span>Any additional comments?</span>
          </div>
          <textarea
            value={request.additionalComments ?? ''}
            onChange={onAdditionalCommentsChange}
            className='comments-field'
            rows='5'
          />
        </Grid>
        <Grid item sm={4} sx={{ alignItems: 'center', display: 'flex' }}>
          <div className='dropzone-title'>How did you hear about us?</div>
        </Grid>
        <Grid item sm={8}>
          <FormControl fullWidth size='small'>
            <InputLabel>Please select one</InputLabel>
            <Select
              className='select_'
              value={request.heardAboutUs ?? ''}
              label='Please select one'
              onChange={onHeardAboutUsChange}
              size='small'
              fullWidth
              placeholder='Please select one'
            >
              {heardAboutUsOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <PreviousNextWrap>
        <div className='prev-direction' onClick={prevStep}>
          <RightArrow />
          <span>Previous</span>
        </div>
        <SubmitButton
          style={{ width: '100px', minWidth: '100px', padding: '0 16px' }}
          noArrow
          text='Finish'
          isSubmitting={submitting}
          onClick={onSubmit}
        />
      </PreviousNextWrap>
    </IndividualStepWrap>
  );
}
