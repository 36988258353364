import React from 'react';
import styled from '@emotion/styled';

export const Root = styled.div`
  text-align: center;
  max-width: 164px;
  width: 100%;
  margin-top: 20px;
  .bioName {
    color: #000;
    font-size: 15px;
    font-family: Syne;
    text-align: center;
    line-height: 1.3;
    margin-top: 20px;
    br {
      display: none;
    }
  }
  .bioTitle {
    color: #0318c6;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 10px;
    min-height: 30.8px;
    border-radius: 10px;
    background-color: rgba(3, 24, 198, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .socialyOff {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    margin-top: 10px;
    justify-content: center;
    min-height: 19.6px;
    .socialIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      flex: 0 0 auto;
      svg {
        height: auto;
        width: 100%;
        max-width: 20px;
        path {
          fill: #9c9c9c;
        }
      }
    }
    a {
      :hover {
        path {
          fill: #0318c6;
        }
      }
    }
  }
  @media (min-width: 1440px) {
    width: 307px;
    max-width: 307px;
    margin-top: 102px;
    .silhouette {
      max-height: 265px;
    }
    .bioName {
      font-size: 33px;
      margin-top: 40px;
      min-height: 85.8px;
      br {
        display: block;
      }
    }
    .bioTitle {
      font-size: 17px;
      min-height: 40px;
      margin-top: 20px;
      font-weight: 500;
    }
    .socialyOff {
      gap: 18px;
      margin-top: 20px;
      min-height: 40px;
      align-items: flex-end;
      .socialIcon {
        max-height: 40px;
        align-items: flex-end;
        padding: 10px;
        svg {
          height: auto;
          width: 100%;
        }
      }
    }
  }
`;

export function PodcastIcon({ ...props }) {
  return (
    <svg
      width='19'
      height='20'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_552_178)'>
        <path
          d='M12.0397 12.7483C12.0049 12.439 11.9005 12.2148 11.6956 12.0061C11.3091 11.6002 10.6288 11.3335 9.82862 11.3335C9.02846 11.3335 8.34814 11.5925 7.96159 12.0061C7.76445 12.2226 7.65235 12.439 7.61756 12.7483C7.54798 13.3513 7.5905 13.8693 7.66008 14.7042C7.72966 15.4966 7.85722 16.5558 8.02344 17.6265C8.14327 18.3919 8.2399 18.8093 8.32494 19.0992C8.47183 19.5824 8.99754 19.996 9.83248 19.996C10.6674 19.996 11.2009 19.5747 11.34 19.0992C11.4251 18.8055 11.5217 18.3919 11.6415 17.6265C11.8039 16.548 11.9353 15.4966 12.0049 14.7042C12.0822 13.8693 12.117 13.3513 12.0474 12.7483H12.0397Z'
          fill='#9C9C9C'
        />
        <path
          d='M11.947 8.35335C11.947 9.52459 11 10.4755 9.82873 10.4755C8.65749 10.4755 7.71045 9.52846 7.71045 8.35335C7.71045 7.17824 8.65749 6.2312 9.82873 6.2312C11 6.2312 11.947 7.18984 11.947 8.35335Z'
          fill='#9C9C9C'
        />
        <path
          d='M9.80165 1.0953e-05C4.8577 0.0154729 0.810541 4.03557 0.740963 8.98339C0.690711 12.9919 3.24579 16.4206 6.82136 17.6807C6.9064 17.7155 6.99531 17.6382 6.98371 17.5532C6.94119 17.2439 6.88708 16.9308 6.85615 16.6216C6.84069 16.5095 6.77111 16.4244 6.67448 16.3703C3.84881 15.1372 1.87741 12.3 1.9122 9.01818C1.95472 4.70817 5.46845 1.19831 9.76686 1.16352C14.1696 1.12873 17.7607 4.69657 17.7607 9.09549C17.7607 12.3464 15.797 15.1372 12.9984 16.3703C12.894 16.4128 12.8245 16.5095 12.8167 16.6216C12.7742 16.9308 12.7317 17.2439 12.6892 17.5532C12.6737 17.6498 12.7665 17.7155 12.8515 17.6807C16.3923 16.4399 18.9319 13.0615 18.9319 9.09549C18.9126 4.07809 14.8229 -0.00772001 9.80165 1.0953e-05Z'
          fill='#9C9C9C'
        />
        <path
          d='M9.59288 3.01501C6.37293 3.13484 3.78692 5.79816 3.7444 9.02197C3.71734 11.1441 4.77649 13.0227 6.40385 14.1321C6.48116 14.1824 6.59326 14.1244 6.59326 14.0278C6.5662 13.6567 6.5662 13.3281 6.58553 12.9841C6.59326 12.872 6.55074 12.7676 6.4657 12.6903C5.47614 11.7587 4.87312 10.4329 4.90791 8.96785C4.97749 6.38184 7.05326 4.27902 9.6354 4.17466C12.4417 4.06256 14.7494 6.32 14.7494 9.09541C14.7494 10.5102 14.1464 11.7858 13.1916 12.6903C13.1143 12.7676 13.0718 12.872 13.0718 12.9841C13.0873 13.3204 13.0795 13.6489 13.0641 14.02C13.0563 14.1167 13.1684 14.1824 13.2535 14.1244C14.8538 13.0305 15.9129 11.175 15.9129 9.09155C15.9207 5.659 13.0525 2.87585 9.59288 3.01501Z'
          fill='#9C9C9C'
        />
      </g>
      <defs>
        <clipPath id='clip0_552_178'>
          <rect width='18.191' height='20' fill='white' transform='translate(0.740967)' />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BigPocketsIcon({ ...props }) {
  return (
    <svg
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M17.6906 1.15122C17.6886 0.955653 17.6543 0.758072 17.614 0.56654C17.5696 0.356863 17.4537 0.177427 17.2521 0.0957741C17.112 0.0383144 16.9497 0.0100886 16.7975 0.0100886C14.2713 0.00504829 11.744 0.00605635 9.21783 0.00605635C6.67045 0.00504829 4.12408 0.0110967 1.57771 7.96374e-06C1.08174 -0.00200817 0.705734 0.37904 0.708758 0.850814C0.714807 1.66936 0.713799 2.48791 0.710774 3.30747C2.70271 4.60686 5.36803 5.45162 8.32872 5.59174C7.99707 5.19557 7.78336 4.58065 7.78336 3.88811C7.78336 3.51614 7.84485 3.16634 7.95271 2.86089C7.95271 2.85081 7.95473 2.84073 7.95574 2.83065C7.95876 2.80041 7.96985 2.76009 7.99203 2.74497C7.99505 2.74295 7.99807 2.73993 8.0011 2.73791C8.25211 2.12904 8.69465 1.72279 9.19969 1.72279C9.98195 1.72279 10.617 2.69154 10.617 3.88711C10.617 4.57965 10.4033 5.19456 10.0717 5.59073C13.0303 5.45162 15.6967 4.60888 17.6886 3.31049C17.6886 2.59073 17.6967 1.86997 17.6886 1.15021L17.6906 1.15122Z'
        fill='#9C9C9C'
      />
      <path
        d='M17.6957 8.89812H17.6917C17.6917 7.60881 17.6917 6.31949 17.6917 5.03018C17.6917 4.98986 17.7017 4.94248 17.6836 4.91123C17.6574 4.86687 17.6221 4.81244 17.5818 4.79127C16.4285 5.81849 14.9356 6.62998 13.23 7.13199C13.2108 7.15316 13.1664 7.16022 13.1332 7.16425C13.1251 7.16425 13.1181 7.16627 13.11 7.16627C12.6292 7.30336 11.1372 7.5826 11.0152 7.58865C10.4265 7.66123 9.82169 7.70054 9.20375 7.70054C8.5858 7.70054 6.85899 7.55034 6.8106 7.50498C4.41947 7.10881 2.32673 6.13099 0.81665 4.7832C0.779351 4.81647 0.74911 4.86788 0.724916 4.91123C0.703747 4.94953 0.713827 5.00599 0.713827 5.05437C0.713827 6.4324 0.720884 7.81042 0.709795 9.18845C0.707779 9.47675 0.745077 9.75901 0.782376 10.0413C1.08379 11.8638 2.5979 17.129 10.0031 16.8165C10.5304 16.7641 11.0586 16.7157 11.5858 16.6653C11.6221 16.6622 11.6584 16.6582 11.6947 16.6602C11.8187 16.6663 11.866 16.7227 11.8247 16.8417C11.7884 16.9475 11.739 17.0513 11.6775 17.1441C11.5253 17.3759 11.3691 17.6038 11.2058 17.8276C10.8509 18.3134 10.4064 18.7136 9.92451 19.0634C9.55758 19.3296 9.15032 19.5393 8.76524 19.7802C8.7108 19.8145 8.67955 19.885 8.63722 19.9384C8.69467 19.9576 8.75314 19.9949 8.8106 19.9929C8.8106 19.9929 16.0314 20.4717 17.5152 13.7036C17.5213 13.6794 17.5283 13.6542 17.5354 13.63C17.614 13.3205 17.6836 12.9989 17.6886 12.6814C17.7058 11.4203 17.6957 10.1592 17.6957 8.89812Z'
        fill='#9C9C9C'
      />
    </svg>
  );
}

export function FacebookIcon() {
  return (
    <svg width='10' height='20' viewBox='0 0 10 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.55105 20H2.39046V19.6993C2.39046 16.5681 2.38817 13.4365 2.3955 10.3052C2.39596 10.0756 2.34279 10.0027 2.10539 10.0128C1.63286 10.033 1.1585 10.0188 0.685064 10.0188C0.603484 10.0188 0.521445 10.0188 0.42749 10.0188V6.49617H2.39046C2.39046 6.38434 2.39 6.29635 2.39046 6.20881C2.3955 5.43746 2.37763 4.6652 2.41062 3.89523C2.45462 2.86768 2.76903 1.93134 3.4895 1.16687C4.1096 0.509647 4.89607 0.137495 5.77696 0.0834135C7.05474 0.00504148 8.33849 0.0233741 9.63827 0V3.45525C9.56677 3.45525 9.48703 3.45525 9.40682 3.45525C8.77297 3.45525 8.13912 3.45341 7.50527 3.4557C6.87921 3.458 6.55197 3.77286 6.54509 4.39479C6.53684 5.08089 6.54326 5.76745 6.54326 6.48288H9.70885C9.58694 7.67359 9.46823 8.83175 9.34678 10.0147H6.55105V19.9995V20Z'
        fill='#9C9C9C'
      />
    </svg>
  );
}

export function BigPocketIcon() {
  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.6906 1.15122C17.6886 0.955653 17.6543 0.758072 17.614 0.56654C17.5696 0.356863 17.4537 0.177427 17.2521 0.0957741C17.112 0.0383144 16.9497 0.0100886 16.7975 0.0100886C14.2713 0.00504829 11.744 0.00605635 9.21783 0.00605635C6.67045 0.00504829 4.12408 0.0110967 1.57771 7.96374e-06C1.08174 -0.00200817 0.705734 0.37904 0.708758 0.850814C0.714807 1.66936 0.713799 2.48791 0.710774 3.30747C2.70271 4.60686 5.36803 5.45162 8.32872 5.59174C7.99707 5.19557 7.78336 4.58065 7.78336 3.88811C7.78336 3.51614 7.84485 3.16634 7.95271 2.86089C7.95271 2.85081 7.95473 2.84073 7.95574 2.83065C7.95876 2.80041 7.96985 2.76009 7.99203 2.74497C7.99505 2.74295 7.99807 2.73993 8.0011 2.73791C8.25211 2.12904 8.69465 1.72279 9.19969 1.72279C9.98195 1.72279 10.617 2.69154 10.617 3.88711C10.617 4.57965 10.4033 5.19456 10.0717 5.59073C13.0303 5.45162 15.6967 4.60888 17.6886 3.31049C17.6886 2.59073 17.6967 1.86997 17.6886 1.15021L17.6906 1.15122Z'
        fill='#9C9C9C'
      />
      <path
        d='M17.6957 8.89812H17.6917C17.6917 7.60881 17.6917 6.31949 17.6917 5.03018C17.6917 4.98986 17.7017 4.94248 17.6836 4.91123C17.6574 4.86687 17.6221 4.81244 17.5818 4.79127C16.4285 5.81849 14.9356 6.62998 13.23 7.13199C13.2108 7.15316 13.1664 7.16022 13.1332 7.16425C13.1251 7.16425 13.1181 7.16627 13.11 7.16627C12.6292 7.30336 11.1372 7.5826 11.0152 7.58865C10.4265 7.66123 9.82169 7.70054 9.20375 7.70054C8.5858 7.70054 6.85899 7.55034 6.8106 7.50498C4.41947 7.10881 2.32673 6.13099 0.81665 4.7832C0.779351 4.81647 0.74911 4.86788 0.724916 4.91123C0.703747 4.94953 0.713827 5.00599 0.713827 5.05437C0.713827 6.4324 0.720884 7.81042 0.709795 9.18845C0.707779 9.47675 0.745077 9.75901 0.782376 10.0413C1.08379 11.8638 2.5979 17.129 10.0031 16.8165C10.5304 16.7641 11.0586 16.7157 11.5858 16.6653C11.6221 16.6622 11.6584 16.6582 11.6947 16.6602C11.8187 16.6663 11.866 16.7227 11.8247 16.8417C11.7884 16.9475 11.739 17.0513 11.6775 17.1441C11.5253 17.3759 11.3691 17.6038 11.2058 17.8276C10.8509 18.3134 10.4064 18.7136 9.92451 19.0634C9.55758 19.3296 9.15032 19.5393 8.76524 19.7802C8.7108 19.8145 8.67955 19.885 8.63722 19.9384C8.69467 19.9576 8.75314 19.9949 8.8106 19.9929C8.8106 19.9929 16.0314 20.4717 17.5152 13.7036C17.5213 13.6794 17.5283 13.6542 17.5354 13.63C17.614 13.3205 17.6836 12.9989 17.6886 12.6814C17.7058 11.4203 17.6957 10.1592 17.6957 8.89812Z'
        fill='#9C9C9C'
      />
    </svg>
  );
}

export function YoutubeIcon() {
  return (
    <svg width='29' height='20' viewBox='0 0 29 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.1364 20.0001C12.2132 19.9493 9.85106 19.9089 7.49099 19.8146C6.34878 19.769 5.20865 19.6425 4.07215 19.5155C3.11651 19.4088 2.28732 19.0175 1.64211 18.2775C1.11247 17.6696 0.873557 16.9445 0.736222 16.1682C0.536181 15.0384 0.456372 13.8983 0.384336 12.7556C0.292607 11.2941 0.258403 9.83219 0.32422 8.37075C0.399883 6.69786 0.464145 5.02187 0.824842 3.378C1.11298 2.06737 1.87428 1.12935 3.14501 0.685733C3.69849 0.492429 4.30276 0.40951 4.89149 0.356132C9.04105 -0.0216669 13.202 -0.0330683 17.3635 0.0275661C18.5606 0.0451863 19.7588 0.0612518 20.9539 0.126032C22.2578 0.196513 23.5601 0.306899 24.8599 0.43024C25.5383 0.494502 26.1954 0.665004 26.7743 1.0599C27.5874 1.61546 28.1129 2.37054 28.2994 3.33447C28.4466 4.09473 28.5907 4.86173 28.6446 5.63184C28.7477 7.10934 28.8358 8.591 28.8363 10.0711C28.8374 11.4994 28.7555 12.9302 28.642 14.3549C28.5741 15.2074 28.4383 16.0661 28.2217 16.8932C27.8724 18.2287 26.9722 19.088 25.6258 19.3922C24.8609 19.5653 24.0696 19.6425 23.2849 19.7063C20.3869 19.9436 17.4796 19.9343 14.1364 19.9996V20.0001ZM11.7136 5.70543V14.2699C14.195 12.8364 16.6473 11.4201 19.1271 9.98766C16.6452 8.5542 14.1934 7.13785 11.7136 5.70543Z'
        fill='#9C9C9C'
      />
    </svg>
  );
}

export function InstagramIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
      <g clip-path='url(#clip0_807_175)'>
        <path
          d='M16.8073 20H3.47396C1.62522 20 0.140625 18.4874 0.140625 16.6667V3.33333C0.140625 1.51261 1.65323 0 3.47396 0H16.7793C18.628 0 20.1126 1.51261 20.1126 3.33333V16.6387C20.1126 18.4874 18.6 19.972 16.7793 19.972L16.8073 20ZM3.47396 1.09244C2.24147 1.09244 1.23306 2.10084 1.23306 3.33333V16.6387C1.23306 17.8711 2.24147 18.8796 3.47396 18.8796H16.7793C18.0118 18.8796 19.0202 17.8711 19.0202 16.6387V3.33333C19.0202 2.10084 18.0118 1.09244 16.7793 1.09244H3.47396Z'
          fill='#9C9C9C'
        />
        <path
          d='M10.1406 15.126C8.76808 15.126 7.47957 14.5938 6.49918 13.6134C5.51879 12.633 4.98657 11.3445 4.98657 9.97193C4.98657 8.59938 5.51879 7.31087 6.49918 6.33048C7.47957 5.35008 8.76808 4.81787 10.1406 4.81787C11.5132 4.81787 12.8017 5.35008 13.7821 6.33048C14.7625 7.31087 15.2947 8.59938 15.2947 9.97193C15.2947 11.3445 14.7625 12.633 13.7821 13.6134C12.8017 14.5938 11.5132 15.126 10.1406 15.126ZM10.1406 5.96633C7.92775 5.96633 6.10702 7.78706 6.10702 9.99994C6.10702 12.2128 7.92775 14.0336 10.1406 14.0336C12.3535 14.0336 14.1742 12.2128 14.1742 9.99994C14.1742 7.78706 12.3535 5.96633 10.1406 5.96633Z'
          fill='#9C9C9C'
        />
        <path
          d='M17.5077 2.77312C17.5077 3.30533 17.0595 3.75351 16.5273 3.75351C15.9951 3.75351 15.5469 3.30533 15.5469 2.77312C15.5469 2.2409 15.9951 1.79272 16.5273 1.79272C17.0595 1.79272 17.5077 2.2409 17.5077 2.77312Z'
          fill='#9C9C9C'
        />
      </g>
      <defs>
        <clipPath id='clip0_807_175'>
          <rect width='20' height='20' fill='white' transform='translate(0.140625)' />
        </clipPath>
      </defs>
    </svg>
  );
}
