import React, { useState, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useImmer } from 'use-immer';
import axios from 'axios';
import LogRocket from 'logrocket';

import { Root, FormWrap } from './styles';
import PropertyInformation from './PropertyInformation';
import Banner from './Banner';
import ContactInformation from './ContactInformation';
import PropertyDetails from './PropertyDetails';
import AdditionalInformation from './AdditionalInformation';
import FormCompleted from './FormCompleted';
import Stepper from './Steppers';
import Footer from './Footer';
import { getTrackingObjectForCustomQuote } from '../../seo-tracking';

export default function CustomQuote() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trackingObject = getTrackingObjectForCustomQuote();
  const { salesRepId } = useParams();

  const {
    utm_source,
    sourceChoice,
    utm_medium,
    utm_campaign,
    content,
    device,
    keyword,
    adgroup,
    identifier,
  } = Object.fromEntries(queryParams);

  const [request, setRequest] = useImmer({
    url: window.location.href,
    salesRepId,
    source: utm_source ?? trackingObject['utm_source'],
    sourceChoice: parseInt(sourceChoice),
    medium: utm_medium ?? trackingObject['utm_medium'],
    campaign: utm_campaign ?? trackingObject['utm_campaign'],
    content: content ?? trackingObject['content'],
    device: device ?? trackingObject['device'],
    keyword: keyword ?? trackingObject['keyword'],
    adgroup: adgroup ?? trackingObject['adgroup'],
    identifier: identifier ?? trackingObject['identifier'],
  });

  const [step, setStep] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef(null);

  const handleNextStep = () => {
    formRef.current.scrollIntoView();
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await axios.post(
        // 'http://localhost:7071/api/SendFeasibilityAnalysisRequest',
        'https://specs-feasibility-form.azurewebsites.net/api/SendFeasibilityAnalysisRequest',
        request
      );
    } catch (error) {
      LogRocket.captureException(error, {
        tags: {
          message: 'Failed to submit feasibility form',
          email: request?.contactInfo?.email ?? '',
          phone: request?.contactInfo?.phone ?? '',
        },
      });
    }
    // Show success message whether it succeeded or failed
    setStep(5);
    setSubmitting(false);
  };

  return (
    <>
      <Root>
        <Banner stepOne={step === 1} />
        <FormWrap ref={formRef}>
          {step !== 5 && <Stepper step={step} />}
          {step === 1 && (
            <ContactInformation
              request={request}
              setRequest={setRequest}
              nextStep={handleNextStep}
              prevStep={handlePrevStep}
            />
          )}
          {step === 2 && (
            <PropertyInformation
              request={request}
              setRequest={setRequest}
              nextStep={handleNextStep}
              prevStep={handlePrevStep}
            />
          )}
          {step === 3 && (
            <PropertyDetails
              request={request}
              setRequest={setRequest}
              nextStep={handleNextStep}
              prevStep={handlePrevStep}
            />
          )}
          {step === 4 && (
            <AdditionalInformation
              request={request}
              setRequest={setRequest}
              onSubmit={onSubmit}
              submitting={submitting}
              prevStep={handlePrevStep}
            />
          )}
          {step === 5 && <FormCompleted />}
        </FormWrap>
      </Root>
      <Footer />
    </>
  );
}
