import React from 'react';
import {
  Root,
  BigPocketsIcon,
  FacebookIcon,
  PodcastIcon,
  YoutubeIcon,
  InstagramIcon,
} from './bioCardStyles';
import { LinkedIn, Twitter } from './illustrations';
import { Silhouette } from './indexStyles';

export default function BioCard({
  img,
  nameFirst,
  showTitle,
  nameLast,
  title,
  linkedIn,
  twitter,
  podcast,
  facebook,
  bigPockets,
  youtube,
  className,
  instagram,
}) {
  return (
    <Root className={className}>
      {img ? (
        <img src={img} alt={nameFirst + ' ' + nameLast} />
      ) : (
        <Silhouette className='silhouette' />
      )}
      {showTitle && <div className='bioTitle'>{title}</div>}
      <div className='bioName'>
        {nameFirst} <br />
        {nameLast}
      </div>
      <div className='socialyOff'>
        {linkedIn && (
          <a href={linkedIn} target='_blank' rel='noopener noreferrer' className='socialIcon'>
            <LinkedIn />
          </a>
        )}
        {twitter && (
          <a
            href={twitter}
            target='_blank'
            rel='noopener noreferrer'
            className='socialIcon socialIconTwitter '
          >
            <Twitter />
          </a>
        )}
        {podcast && (
          <a href={podcast} target='_blank' rel='noopener noreferrer' className='socialIcon'>
            <PodcastIcon />
          </a>
        )}
        {facebook && (
          <a href={facebook} target='_blank' rel='noopener noreferrer' className='socialIcon'>
            <FacebookIcon
              style={{
                height: 20,
                width: 'auto',
              }}
            />
          </a>
        )}
        {bigPockets && (
          <a href={bigPockets} target='_blank' rel='noopener noreferrer' className='socialIcon'>
            <BigPocketsIcon />
          </a>
        )}
        {youtube && (
          <a href={youtube} target='_blank' rel='noopener noreferrer' className='socialIcon'>
            <YoutubeIcon />
          </a>
        )}
        {instagram && (
          <a href={instagram} target='_blank' rel='noopener noreferrer' className='socialIcon'>
            <InstagramIcon />
          </a>
        )}
      </div>
    </Root>
  );
}
